import React, { useState, useEffect } from 'react';
import { getServiceOrder } from '../../../services/api';

import '../../HomePage/styles/Index.css';

export default function TagOS() {

  const [os, setOs] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const response = await getServiceOrder();
      const orders = response.data;
      setOs(orders);
      console.log(orders);
      setLoading(false);
    })();
  }, []);


  if (loading) {
    return <div> Carregando.....  ....  ...  ..</div>
  } else {

    const contadorStatus = {
      "EM ABERTO": 0,
      "PENDENTE": 0,
      "FECHADO": 0,
    };

    os.forEach((order) => {
      contadorStatus[order.status]++;
    });

    return (<>
      <div className="homeBox" >
        <div className="boxText" >
          <span > ORDENS DE SERVIÇO </span>

          <div className="statusBox" >
            <div className={'statusOpen'}></div>
            <div className="statusText">ABERTAS:</div>
            <span> {contadorStatus['EM ABERTO']}</span>
          </div>
          <div className="statusBox" >
            <div className={'statusPending'}></div>
            <div className="statusText">PENDENTES:</div>
            <span> {contadorStatus.PENDENTE}</span>
          </div>
          <div className="statusBox">
            <div className={'statusClosed'}></div>
            <div className="statusText">FECHADAS:</div>
            <span>{contadorStatus.FECHADO}</span>
          </div>
        </div>
      </div>
    </>
    )
  }
};