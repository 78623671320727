import React, { useContext } from 'react'
import { AuthContext } from '../../../context/auth'
import {  MdPlayCircle } from 'react-icons/md';


export default function Logout() {
    const {logout} = useContext(AuthContext);

    const handleLogout = () =>{
        logout();
      }

  return (
      <button className='btnLogout' onClick={handleLogout}>logout <MdPlayCircle className="icons" /></button>
  )
}
