import axios from 'axios';

export const api = axios.create({
    baseURL: 'https://api.apitsolutions.com.br/',
    //baseURL: 'http://localhost:3001/',
});

export const createSession = async (email, password) => {
    return api.post('/user/login', { email, password });
}

export const getUser = async () => {
    return api.get('/user')
}

export const getUserVerifying = async (email) => {
    return api.get('/user/verifying', { email: email });
}
export const postUserVerify = async (email) => {
    return api.post('/user/forgetPassword', { email: email })
}

export const sendMessage = async (name, email, subject) => {
    return api.get('/user/sendMessage', { name: name, email: email, subject: subject });
}

export const getCustomer = async () => {
    return api.get('/customers')
}

export const getCustomerById = async () => {
    return api.get('/customers/:id')
}

export const newCustomer = async () => {
    return api.post('/customers');
}

export const updateCustomer = async () => {
    return api.get('/customers/:id')
}

export const deleteCustomer = async () => {
    return api.delete('/customers/:id')
}

export const getCompany = async () => {
    return api.get('/company');
}

export const getServiceOrder = async () => {
    return api.get('/serviceOrders')
}

