import React from 'react';
import { Form } from 'react-bootstrap';

function Occurrence({ newOccurrence }) {

    const onChange = (event) => {
        const occurrence = event.target.value
        newOccurrence(occurrence);
    }

  return (
      <>
        <Form.Label  htmlFor="occurrence" className="" >Solicitação / Defeito Reclamado</Form.Label>
        <input type="text" className="searchCustomer" name="occurrence" onChange={onChange}   required/>
     </>
  )
}

export default Occurrence