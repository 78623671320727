import React, { useState } from 'react'
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import logo from '../../NavBar/img/apitLogo.jpg';

import '../styles/NewServiceOrder.css';

import Logout from '../../NavBar/components/Logout';
import Nav from '../../NavBar/components/Nav'
import Topo from '../../NavBar/components/Topo'
import SearchCustomer from '../components/SearchCustomer';
import Occurrence from '../components/Occurrence';
import Calendar from '../components/Calendar';

import { api } from '../../../services/api';

function NewServiceOrder() {
    const [selectedDate, setSelectedDate] = useState('');
    const [occurrence, setOccurrence] = useState('');
    const [formData, setFormData] = useState({
        date: '',
        customer_id: '',
        occurrence: '',
        requester: '',
        attendant: '',
        technician: '',
        observations: ''
    });

    const navigate = useNavigate();
    const [message, setMessage] = useState('');


    const handleDateChange = (date) => {
        setSelectedDate(date);
        handleFormChange('date', date); // Atualize o estado formData com a data selecionada
    };

    const handleOccurrenceChange = (occurrence) => {
        setOccurrence(occurrence);
        handleFormChange('occurrence', occurrence);
    }

    const handleFormChange = (name, value, id, idFieldName, requester, attendant, technician, observations) => {
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
            [idFieldName]: id,// Atualize o valor do ID no estado formData
            [requester]: requester,
            [attendant]: attendant,
            [technician]: technician,
            [observations]: observations
        }));
    };

    const handleCustomerChange = (name, value, id) => {
        handleFormChange('customer', value, id, 'customer_id');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await api.post('/serviceOrders', formData);
            setMessage(alert('Ordem de serviço gerada com sucesso') && 'O.S criada com sucesso', message);
            setFormData({
                date: '',
                customer_id: '',
                occurrence: '',
                requester: '',
                attendant: '',
                technician: '',
                observations: ''
            });
        } catch (error) {
            console.log(error);
            setMessage("Erro ao tentar criar o registro :(");
        }
        console.log(formData, selectedDate, occurrence);
        navigate('/serviceOrders');

    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    return (
        <>
            <Topo logo={logo} companyName={"AP. IT Solutions"} logout={<Logout />} />
            <Nav />
            <section className="center">
                <div className="container">
                    <div className="row">
                        <div className="mainTitle">
                            Nova Ordem de Serviço
                        </div>
                        <div className="section1">
                            <Form onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
                                <div className="centerBox">
                                <Form.Label htmlFor="date" className="" >Data da Solicitação</Form.Label>
                                    <Calendar className="searchCustomer" onDateChange={handleDateChange} required />

                                    <SearchCustomer
                                        className="searchCustomer"
                                        name="customer"
                                        id="id"
                                        value={formData.customer}
                                        onChange={handleCustomerChange}
                                    />

                                    <Occurrence
                                        className="searchCustomer"
                                        name="occurrence"
                                        value={formData.occurrence}
                                        newOccurrence={handleOccurrenceChange}
                                    />


                                    <div className="row">

                                        <div className="col-4">
                                            <Form.Label htmlFor="requester" >Solicitante</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                className="searchCustomer"
                                                id="requester"
                                                name="requester"
                                                placeholder="Solicitado por"
                                                value={formData.requester}
                                                onChange={(event) => handleFormChange('requester', event.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="col-4">
                                            <Form.Label htmlFor="attendant" >Atendente</Form.Label>
                                            <Form.Select
                                                size="sm"
                                                className="searchCustomer"
                                                id="attendant"
                                                name="attendant"
                                                value={formData.attendant}
                                                onChange={(event) => handleFormChange('attendant', event.target.value)}
                                            >
                                                <option value="">Selecione um atendente</option>
                                                <option value="Atendente 1">Atendente 1</option>
                                                <option value="Atendente 2">Atendente 2</option>
                                                <option value="Atendente 3">Atendente 3</option>
                                                {/* Adicione mais opções conforme necessário */}
                                            </Form.Select>
                                        </div>
                                        <div className="col-4">
                                            <Form.Label htmlFor="technician" >Técnico</Form.Label>
                                            <Form.Select
                                                size="sm"
                                                className="searchCustomer"
                                                id="technician"
                                                name="technician"
                                                value={formData.technician}
                                                onChange={(event) => handleFormChange('technician', event.target.value)}
                                            >
                                                <option value="">Selecione um técnico</option>
                                                <option value="Técnico 1">Técnico 1</option>
                                                <option value="Técnico 2">Técnico 2</option>
                                                <option value="Técnico 3">Técnico 3</option>
                                                {/* Adicione mais opções conforme necessário */}
                                            </Form.Select>
                                        </div>
                                    </div>

                                    <Form.Label htmlFor="observations" className='' >Observações</Form.Label>

                                    <input
                                        size="sm"
                                        type="text"
                                        className="searchCustomer"
                                        id="observations"
                                        name="observations"
                                        placeholder="obs:"
                                        value={formData.observations}
                                        onChange={(event) => handleFormChange('observations', event.target.value)}

                                    />

                                </div>
                                <button className="form-btn" type="submit">Enviar</button>
                            </Form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default NewServiceOrder

