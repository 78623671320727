import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { MdEdit, MdDelete } from 'react-icons/md';

import { api } from '../../../services/api';
import { getCustomer } from '../../../services/api';

import logo from '../../NavBar/img/apitLogo.jpg';
import Topo from '../../NavBar/components/Topo';
import Logout from '../../NavBar/components/Logout';
import Nav from '../../NavBar/components/Nav';

import '../styles/Customer.css';


export default function GetCustomerComponent() {
  const [customers, setCustomers] = useState([]);
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const response = await getCustomer();
      setCustomers(response.data);
      const token = response.data.token;
      setToken(token);
      setCustomers(response.data, token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
      setLoading(false);
    })()
  }, [token]);


  if (loading) {
    return <div>Carregando.....  ....  ...  ..  .</div>
  }



  async function handleDelete(id) {
    const confirmDelete = window.confirm("Confirma a exclusão desse cliente ?");
    if (confirmDelete) {//
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      try {
        // await api.delete(`http://localhost:3001/customers/${customerId}`);
        await api.delete(`/customers/${id}`);
        setCustomers(customers.filter(customer => customer.id !== id))
      } catch (error) {
        console.log(error);
      }
    }
  }

  const path = '/selectedCustomer/';

  return (
    <>
      <Topo logo={logo} companyName={"AP. IT Solutions"} logout={<Logout />} />
      <Nav />

      <section className="center">
        <div className="container">
          <div className="row">
            <div className="mainTitle">
              Clientes
            </div>
            <div>
              <table className='tableOS'>
                <thead>
                  <tr>
                    <th>Clientes</th>
                    <th>CNPJ</th>
                    {/* <th>Address</th> */}
                    <th>Email</th>
                    {/* <th>Web</th> */}
                    <th>Telefone</th>
                    <th>Celular</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className='orders'>
                  {customers.map(customer => (
                    <tr key={customer.id}>
                      <td>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a href={path + customer.id}>
                          {customer.name}
                        </a>
                      </td>
                      <td>{customer.cnpj}</td>
                      {/* <td>{customer.street}</td> */}
                      <td>{customer.email}</td>
                      {/* <td>{customer.website}</td> */}
                      <td>{customer.phone}</td>
                      <td>{customer.mobile}</td>
                      <td>
                        <Link to={"/updateCustomer/" + customer.id}>
                          <MdEdit className='icon' />
                          {/* <Button className='btn  btn-warning' >Edit</Button> */}
                        </Link>
                      </td>
                      <td>
                        {/* <Button className='btn  btn-danger' onClick={() => handleDelete(customer.id)}>Delete</Button> */}
                        <Link to={"/deleteCustomer/"}>
                          <MdDelete className='icon' onClick={() => handleDelete(customer.id)} />
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
