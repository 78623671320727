import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import { api } from '../../../services/api';

import { getUserVerifying } from '../../../services/api';

import axios from 'axios';

import '../styles/ForgetPassword.css';


function ForgetPassword() {
     
    const [token, setToken] = useState('');
    
    const [user, setUser] = useState({username:"", token: ""});
    
    const[message, setMessage] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        (async() => {
        const response = await getUserVerifying();
        setUser(response.data);
        const token = response.data.token;
        setUser(response.data, token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
        setToken(token)
      })()},[token]);

 
    
      const handleSubmit = async (e) => {
        e.preventDefault();
    
        if(!user || user.username === ""){
            setMessage("Informe seu email cadastrado, ou faça um novo cadastro");
            return;
        }
    
        try {
            const response = await api.post('/authenticate', { username: user.username });
            const token = response.data.token;
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            
            await api.post('/user/forgetPassword', { usernaem: user.username });
            
            setMessage(alert('Cliente criado com sucesso') && 'Cliente criado com sucesso');
    
            goLogin();
        } catch (error) {
            console.log(error);
        }
    }

    const handleChange = (e) =>{
        setUser( {
            ...user,
            [e.target.name]: e.target.value
        })
    }

    const goLogin = () =>{
        setMessage("Verifique sua caixa de email");
        setUser("");
        setTimeout(() =>{
            navigate('/login');
        },3000)
       
    }

 
    return (
    <>
    <div className="main">
         <div className="loginBox centerLogin">
                <div><h5>Recupere seu Login</h5></div>
               {message && <div className="message">{message}</div>}
                {/* <p>{String(authenticated)}</p> */}
                <Form onSubmit={handleSubmit} className="formForget">
                    <Form.Group>
                       <Form.Label className="form-label" htmlFor="email" >Email</Form.Label>
                        <Form.Control
                            size="sm"
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            placeholder="Digite o email cadastrado para recuperar a Password"
                            value={user.email}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label className="form-label"  ><a href="/">Não tem registro ?, Registre-se aqui</a></Form.Label>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label className="form-label"  ><a href="/login">Sair</a></Form.Label>
                    </Form.Group>
                    <Form.Group className="loginBtn">
                        <Button className="form-btn btn" variant="primary" type="submit">Enviar</Button>
                    </Form.Group>
                </Form>
         </div>
    </div>
    </>
  )
}

export default ForgetPassword