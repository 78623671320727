import React, { useContext } from 'react';
import { AuthContext } from '../../context/auth';

import Topo from '../NavBar/components/Topo';
import Logout from '../NavBar/components/Logout';
import NavBar from '../NavBar/components/Nav';

import logo from '../NavBar/img/apitLogo.jpg';
import TagCompany from '../HomePage/components/Company';
import TagUser from '../HomePage/components/Users';
import TagOS from '../HomePage/components/SO';

import './styles/Index.css';

const HomePage = () => {
  const { authenticated } = useContext(AuthContext);
  console.log(authenticated);



  return (
    <>
      <Topo logo={logo} companyName={"A.P I.T Solutions"} logout={<Logout />} />
      <NavBar />

      <section className="center">
        <div className="container">
          <div className="row">
            <div className="mainTitle">
              Bem-vindo
            </div>
            <TagCompany />

            <TagUser />

            <TagOS />

          </div>
        </div>
      </section>
    </>
  )
}

export default HomePage