import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

import logo from '../../NavBar/img/apitLogo.jpg';

import '../styles/NewServiceOrder.css';

import Logout from '../../NavBar/components/Logout';
import Nav from '../../NavBar/components/Nav';
import Topo from '../../NavBar/components/Topo';
import Calendar from '../components/Calendar';

import { api } from '../../../services/api';

function UpdateServiceOrder() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [message, setMessage] = useState('');

    const [selectedDate, setSelectedDate] = useState('');
    const [selectedStartDate, setSelectedStartDate] = useState('');
    const [selectedFinishDate, setSelectedFinishDate] = useState('');
    const [occurrence, setOccurrence] = useState('');
    const [reopen, setReopen] = useState('');

    const [formData, setFormData] = useState({
        number: '',
        date: '',
        occurrence: '',
        requester: '',
        attendant: '',
        technician: '',
        observations: '',
        servicePerformed: '',
        equipament: '',
        brand: '',
        model:'',
        couterService:'',
        startDate: '',
        finishDate: '',
        status: '',
        kmStart: '',
        kmFinish: '',
        hospedage: '',
        budget: '',
        sale: '',
        guarantee: '',
        others: '',
        address:{
            number: '',
        }
    });

    const [originalStatus, setOriginalStatus] = useState('');

    useEffect(() => {
        const fetchServiceOrder = async () => {
          try {
            const response = await api.get(`/serviceOrders/${id}`);
            const serviceOrderData = response.data;
            setFormData(serviceOrderData);
            setOriginalStatus(serviceOrderData.status);
            setSelectedStartDate(serviceOrderData.startDate);
            setSelectedFinishDate(serviceOrderData.finishDate);
          } catch (error) {
            console.log(error);
            setMessage('Erro ao carregar a ordem de serviço');
          }
        };
      
        fetchServiceOrder();
      }, [id]);

      const handleDateChange = (name, date) => {
        handleFormChange(name, date);
      };
      
      const handleStartDateChange = (name, startDate) => {
        handleFormChange(name, startDate);
      };
      
      const handleFinishDateChange = (name, finishDate) => {
        handleFormChange(name, finishDate);
      };

/*
    const handleDateChange = (date) => {
    setSelectedDate(date);
    handleFormChange('date', date);
};

    const handleStartDateChange = (startDate) => {
        setSelectedStartDate(startDate);
        handleFormChange('startDate', startDate);

    };

    const handleFinishDateChange = (finishDate) => {
        setSelectedFinishDate(finishDate);
        handleFormChange('finishDate', finishDate);
    };
*/
    const handleOccurrenceChange = (occurrence) => {
        setOccurrence(occurrence);
        handleFormChange('occurrence', occurrence);
    };

    
    const handleFormChange = (name, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleCustomerChange = (name, value, id) => {
        handleFormChange('customer', value);
        handleFormChange('customer_id', id);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await api.put(`/serviceOrders/${id}`, formData);
            setMessage('Ordem de serviço atualizada com sucesso');
            setFormData({
                ...formData,
                occurrence: '',
                requester: '',
                attendant: '',
                technician: '',
                observations: '',
                servicePerformed: '',
                equipament: '',
                brand: '',
                model:'',
                counterService: '',
                startDate: '',
                finishDate: '',
                status: '',
                kmStart: '',
                kmFinish: '',
                hospedage: '',
                budget:'',
                sale: '',
                guarantee: '',
                others: ''
            });
            console.log(formData);
        } catch (error) {
            console.log(error);
            setMessage('Erro ao atualizar a ordem de serviço');
        }

        navigate('/serviceOrders');
    };

    const reopenOrder = async (e) => {
        e.preventDefault();
        if (originalStatus === 'FECHADO' && formData.status === 'FECHADO') {
            // Atualize o campo "status" apenas se o status original for "FECHADO"
            const updatedData = {status: 'EM ABERTO' };
            setFormData(updatedData);
            try {
              await api.put(`/serviceOrders/${id}`, formData);
            
            } catch (error) {
              console.log(error);
              setMessage('Erro ao atualizar a ordem de serviço');
            }
          }
        
        navigate('/serviceOrders');
    }


    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };



if(formData.status === 'FECHADO') {
    return(
        <>
        <Topo logo={logo} companyName={'AP. IT Solutions'} logout={<Logout />} />
        <Nav />
        <section className="center">
            <div className="container">
                <div className="row">
                    <div className="mainTitle">Atualizar Ordem de Serviço</div>
                    <div className="section1">
                        <Form onSubmit={reopenOrder} onKeyDown={handleKeyDown}>
                            <div className="centerBox">
                                <div className="returnContent">
                                    <div className="row">
                                        <table>
                                            <tbody>
                                            <tr>
                                                <td className="labelContent1"> <Form.Label htmlFor="" className="labelTitle">Ordem de Serviço número</Form.Label></td>
                                                <td className="labelContent"><Form.Label htmlFor="" className="labelContent">{formData.number}</Form.Label></td>
                                                <td className="labelContent"><Form.Label htmlFor="" className="labelTitle">Data da solicitação</Form.Label></td>
                                                <td className="labelContent2"><Form.Label htmlFor="" className="labelContent">{moment(formData.date).format('DD/MM/YYYY')}</Form.Label></td>
                                                <td className="labelContent"><Form.Label htmlFor="" className="labelTitle">Status</Form.Label></td>
                                                <td className="labelContent3"><Form.Label htmlFor="" className="labelContent">{formData.status}</Form.Label></td>
                                                <td> <div className={`statusOS ${formData.status === 'EM ABERTO' ? 'statusOpen' : formData.status === 'PENDENTE' ? 'statusPending' : 'statusClosed'}`}></div>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                              
                                    <div>
                                        <Form.Label htmlFor="" className="labelTitle">Cliente</Form.Label>
                                        <Form.Label htmlFor="" className="labelContent">{formData.name}</Form.Label>
                                    </div>
                                    <div>
                                        <Form.Label htmlFor="" className="labelTitle">Endereço</Form.Label>
                                        <Form.Label htmlFor="" className="labelContent">{formData.street}, {formData.address_number} - {formData.neighborhood} {formData.city} {formData.zipcode}</Form.Label>
                                    </div>
                                </div>

                                <div className="returnContent">
                                    <div className="row rowLabel">
                                        <Form.Label htmlFor="" className='labelTitle'>Solicitação / Defeito reclamado</Form.Label>
                                        <Form.Label htmlFor="" className="labelContent">{formData.occurrence}</Form.Label>
                                    </div>
                                </div>

                                <div className="returnContent">
                                    <div className="row rowLabel">
                                        <Form.Label htmlFor="servicePerformed">Serviço Executado</Form.Label>
                                        <Form.Label htmlFor="" className="labelContent">{formData.servicePerformed}</Form.Label>
                                    </div>
                                </div>


                                <div className="returnContent">
                                    <div className="row">
                                        <div className="col-4">
                                            <Form.Label htmlFor="requester">Solicitante</Form.Label>
                                            <Form.Label htmlFor="" className="labelContent">{formData.requester}</Form.Label>
                                        </div>

                                        <div className="col-4">
                                            <Form.Label htmlFor="attendant">Atendente</Form.Label>
                                            <Form.Label htmlFor="" className="labelContent">{formData.attendant}</Form.Label>
                                        </div>
                                        <div className="col-4">
                                            <Form.Label htmlFor="technician">Técnico</Form.Label>
                                            <Form.Label htmlFor="" className="labelContent">{formData.technician}</Form.Label>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="returnContent">
                                    <div className="row">
                                        <div className="col-4">
                                            <Form.Label htmlFor="equipament">Equipamento</Form.Label>
                                            <Form.Label htmlFor="" className="labelContent">{formData.equipament}</Form.Label>
                                        </div>

                                        <div className="col-4">                                            
                                            <Form.Label htmlFor="brand">Marca</Form.Label>        
                                            <Form.Label htmlFor="" className="labelContent">{formData.brand}</Form.Label>                                            
                                        </div>

                                        <div className="col-4">
                                            <Form.Label htmlFor="model">Modelo</Form.Label>
                                            <Form.Label htmlFor="" className="labelContent">{formData.model}</Form.Label>
                                        </div>
                                    </div>
                                </div>
{/* 
                                <div className="returnContent">
                                    <div className="row">
                                        <div className="col-4">
                                            <Form.Label htmlFor="startCounter">Contador Inicial</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                className="searchCustomer"
                                                id="startCounter"
                                                name="startCounter"
                                                placeholder="000.000"
                                                value={formData.startCounter}
                                                onChange={(event) =>{
                                                    handleFormChange('startCounter', event.target.value, formData.occurrence, 'startCounter')
                                                }
                                                }
                                            />
                                        </div>

                                        <div className="col-4">
                                        <Form.Label htmlFor="finalCounter">Contador Final</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                className="searchCustomer"
                                                id="finalCounter"
                                                name="finalCounter"
                                                placeholder="000.000"
                                                value={formData.finalCounter}
                                                onChange={(event) =>{
                                                    handleFormChange('finalCounter', event.target.value, formData.occurrence, 'finalCounter')
                                                }
                                                }
                                            />
                                        </div>
                                        <div className="col-4">
                                        <Form.Label htmlFor="serviceCounter">Contador Total</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                className="searchCustomer"
                                                id="serviceCounter"
                                                name="serviceCounter"
                                                placeholder="000.000"
                                                value={formData.serviceCounter}
                                                onChange={(event) =>
                                                    handleFormChange('serviceCounter', event.target.value)
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
 */}

                                <div className="returnContent">
                                    <div className="row">
                                        <div className="col-4">
                                            <Form.Label htmlFor="startDate">Data do Atendimento</Form.Label>
                                            <Form.Label htmlFor="" className="labelContent">{moment(formData.startDate).format('DD/MM/YYYY')}</Form.Label>
                                        </div>

                                        <div className="col-4">
                                            <div>
                                            <Form.Label htmlFor="finishDate">Data de Finalização</Form.Label>
                                            <Form.Label htmlFor="" className="labelContent">{moment(formData.finishtDate).format('DD/MM/YYYY')}</Form.Label>
                                            </div>
                                        </div>
{/* 
                                        <div className="col-4">
                                            <Form.Label htmlFor="technician">Status</Form.Label>
                                            <Form.Select
                                                size="sm"
                                                className="searchCustomer"
                                                id="status"
                                                name="status"
                                                value={formData.status}
                                                onChange={(event) =>
                                                    handleFormChange('status', event.target.value)
                                                }
                                            >
                                                <option value="">Selecione um status</option>
                                                <option value="EM ABERTO">Em Aberto</option>
                                                <option value="PENDENTE">Pendente</option>
                                                <option value="FECAHDO">Fechado</option>
                                            </Form.Select>
                                        </div>
 */}
                                    </div>
                                </div>
{/* 
                                <div className="returnContent">
                                    <div className="row">
                                        <div className="col-4">
                                            <Form.Label htmlFor="kmStart">Kilomentragem Inicial</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                className="searchCustomer"
                                                id="kmStart"
                                                name="kmStart"
                                                placeholder="KM"
                                                value={formData.kmStart}
                                                onChange={(event) =>
                                                    handleFormChange('kmStart', event.target.value)
                                                }
                                            />
                                        </div>

                                        <div className="col-4">
                                            <Form.Label htmlFor="kmFinish">Kilometragem Final</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                className="searchCustomer"
                                                id="kmFinish"
                                                name="kmFinish"
                                                placeholder='KM'
                                                value={formData.kmFinish}
                                                onChange={(event) =>
                                                    handleFormChange('kmFinish', event.target.value)
                                                }
                                            />
                                               
                                        </div>
                                        <div className="col-4">
                                        <Form.Label htmlFor="hospedage">Pernoite / Hospedagem</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                className="searchCustomer"
                                                id="hospedage"
                                                name="hospedage"
                                                placeholder='Hospedagem'
                                                value={formData.hospedage}
                                                onChange={(event) =>
                                                    handleFormChange('hospedage', event.target.value)
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
 */}
                                <div className="returnContent">
                                    <div className="row">
                                        <div className="col-4">
                                            <Form.Label htmlFor="budget">Valor do Orçamento</Form.Label>
                                            <Form.Label htmlFor="" className="labelContent">{formData.budget}</Form.Label>
                                    </div>

                                        <div className="col-4">
                                            <Form.Label htmlFor="sale">Valor Aprovado Do Serviço</Form.Label>
                                            <Form.Label htmlFor="" className="labelContent">{formData.sale}</Form.Label>
                                        </div>
                                        
                                        <div className="col-4">
                                            <Form.Label htmlFor="guarantee">Tipo / Período de garantia</Form.Label>
                                            <Form.Label htmlFor="" className="labelContent">{formData.guarantee}</Form.Label>
                                            <Form.Select
                                                size="sm"
                                                className="searchCustomer"
                                                id="guarantee"
                                                name="guarantee"
                                                value={formData.guarantee}
                                                onChange={(event) =>
                                                    handleFormChange('guarantee', event.target.value)
                                                }
                                            >
                                                <option value="">Período de Garantia</option>
                                                <option value="0">Sem Garantia</option>
                                                <option value="1">Garantia Padrão Balcão</option>
                                                <option value="2">15 dias</option>
                                                <option value="3">30 dias</option>
                                                <option value="4">45 dias</option>
                                                <option value="5">60 dias</option>
                                                <option value="6">90 dias</option>
                                                <option value="7">1 mês</option>
                                                <option value="8">2 meses</option>
                                                <option value="9">3 meses</option>
                                                <option value="10">6 meses</option>
                                                <option value="11">1 ano</option>
                                                <option value="12">18 meses</option>
                                                <option value="13">24 mês</option>
                                                <option value="14">Contrato de Outsourcing</option>
                                                <option value="15">Contrato de Manutenção</option>
                                            </Form.Select>
                                        </div>
                                    </div>
                                </div>

                                <div className="returnContent">
                                    <div className="row rowLabel">
                                        <Form.Label htmlFor="others" className="">Outros</Form.Label>
                                        <Form.Label htmlFor="" className="labelContent">{formData.others}</Form.Label>
                                    </div>
                                </div>
                                <div className="returnContent">
                                    <div className="row rowLabel">
                                        <Form.Label htmlFor="observations" className="">Observações</Form.Label>
                                        <Form.Label htmlFor="observations" className="labelContent">{formData.observations}</Form.Label>
                                    </div>
                                </div>
                            </div>
                            <button className="" type="submit">
                                Reabrir O.S
                            </button>
                        </Form>
                    </div>
                </div>
            </div>
        </section>
    </>
    );
} 
    return (
        <>
            <Topo logo={logo} companyName={'AP. IT Solutions'} logout={<Logout />} />
            <Nav />
            <section className="center">
                <div className="container">
                    <div className="row">
                        <div className="mainTitle">Atualizar Ordem de Serviço</div>
                        <div className="section1">
                            <Form onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
                                <div className="centerBox">
                                    <div className="returnContent">
                                        <div className="row">
                                            <table>
                                                <tbody>
                                                <tr>
                                                    <td className="labelContent1"> <Form.Label htmlFor="" className="labelTitle">Ordem de Serviço número</Form.Label></td>
                                                    <td className="labelContent"><Form.Label htmlFor="" className="labelContent">{formData.number}</Form.Label></td>
                                                    <td className="labelContent"><Form.Label htmlFor="" className="labelTitle">Data da solicitação</Form.Label></td>
                                                    <td className="labelContent2"><Form.Label htmlFor="" className="labelContent">{moment(formData.date).format('DD/MM/YYYY')}</Form.Label></td>
                                                    <td className="labelContent"><Form.Label htmlFor="" className="labelTitle">Status</Form.Label></td>
                                                    <td className="labelContent3"><Form.Label htmlFor="" className="labelContent">{formData.status}</Form.Label></td>
                                                    <td> <div className={`statusOS ${formData.status === 'EM ABERTO' ? 'statusOpen' : formData.status === 'PENDENTE' ? 'statusPending' : 'statusClosed'}`}></div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                  
                                        <div>
                                            <Form.Label htmlFor="" className="labelTitle">Cliente</Form.Label>
                                            <Form.Label htmlFor="" className="labelContent">{formData.name}</Form.Label>
                                        </div>
                                        <div>
                                            <Form.Label htmlFor="" className="labelTitle">Endereço</Form.Label>
                                            <Form.Label htmlFor="" className="labelContent">{formData.street}, {formData.address_number} - {formData.neighborhood} {formData.city} {formData.zipcode}</Form.Label>
                                        </div>

                                    </div>

                                    <div className="returnContent">
                                        <div className="row rowLabel">
                                            <Form.Label htmlFor="occurrence">Solicitação / Defeito reclamado</Form.Label>
                                            <Form.Control
                                                size="lg"
                                                type="text"
                                                className="searchCustomer"
                                                id="occurrence"
                                                name="occurrence"
                                                placeholder=""
                                                value={formData.occurrence}
                                                onChange={(event) =>
                                                    handleFormChange('occurrence', event.target.value)
                                                }
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="returnContent">
                                        <div className="row rowLabel">
                                            <Form.Label htmlFor="servicePerformed">Serviço Executado</Form.Label>
                                            <Form.Control
                                                size="lg"
                                                type="text"
                                                className="searchCustomer"
                                                id="servicePerformed"
                                                name="servicePerformed"
                                                placeholder="Serviço Executado"
                                                value={formData.servicePerformed}
                                                onChange={(event) =>
                                                    handleFormChange('servicePerformed', event.target.value)
                                                }
                                                required
                                            />
                                        </div>
                                    </div>


                                    <div className="returnContent">
                                        <div className="row">
                                            <div className="col-4">
                                                <Form.Label htmlFor="requester">Solicitante</Form.Label>
                                                <Form.Control
                                                    size="sm"
                                                    type="text"
                                                    className="searchCustomer"
                                                    id="requester"
                                                    name="requester"
                                                    placeholder="Solicitado por"
                                                    value={formData.requester}
                                                    onChange={(event) =>
                                                        handleFormChange('requester', event.target.value)
                                                    }
                                                    required
                                                />
                                            </div>

                                            <div className="col-4">
                                                <Form.Label htmlFor="attendant">Atendente</Form.Label>
                                                <Form.Select
                                                    size="sm"
                                                    className="searchCustomer"
                                                    id="attendant"
                                                    name="attendant"
                                                    value={formData.attendant}
                                                    onChange={(event) =>
                                                        handleFormChange('attendant', event.target.value)
                                                    }
                                                >
                                                    <option value="">Selecione um atendente</option>
                                                    <option value="Atendente 1">Atendente 1</option>
                                                    <option value="Atendente 2">Atendente 2</option>
                                                    <option value="Atendente 3">Atendente 3</option>
                                                </Form.Select>
                                            </div>
                                            <div className="col-4">
                                                <Form.Label htmlFor="technician">Técnico</Form.Label>
                                                <Form.Select
                                                    size="sm"
                                                    className="searchCustomer"
                                                    id="technician"
                                                    name="technician"
                                                    value={formData.technician}
                                                    onChange={(event) =>
                                                        handleFormChange('technician', event.target.value)
                                                    }
                                                >
                                                    <option value="">Selecione um técnico</option>
                                                    <option value="Técnico 1">Técnico 1</option>
                                                    <option value="Técnico 2">Técnico 2</option>
                                                    <option value="Técnico 3">Técnico 3</option>
                                                </Form.Select>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="returnContent">
                                        <div className="row">
                                            <div className="col-4">
                                                <Form.Label htmlFor="equipament">Equipamento</Form.Label>
                                                <Form.Select
                                                    size="sm"
                                                    className="searchCustomer"
                                                    id="equipament"
                                                    name="equipament"
                                                    value={formData.equipament}
                                                    onChange={(event) =>
                                                        handleFormChange('equipament', event.target.value)
                                                    }
                                                >
                                                    <option value="">Selecione um equipamento</option>
                                                    <option value="PC / DESKTOP">PC / DESKTOP</option>
                                                    <option value="IMPRESSORA LASER">IMPRESSORA LASER</option>
                                                    <option value="IMPRESSORA JATO DE TINTA">IMPRESSORA JATO DE TINTA</option>
                                                    <option value="MONITOR">MONITOR</option>
                                                    <option value="NOTEBOOK">NOTEBOOK</option>
                                                    <option value="INFRA-ESTRUTURA">INFRA-ESTRUTURA</option>
                                                    <option value="OUTRO">OUTRO</option>
                                                </Form.Select>
                                            </div>

                                            <div className="col-4">                                            
                                                <Form.Label htmlFor="brand">Marca</Form.Label>                                                    
                                                    <Form.Select
                                                    size="sm"
                                                    className="searchCustomer"
                                                    id="brand"
                                                    name="brand"
                                                    value={formData.brand}
                                                    onChange={(event) =>
                                                        handleFormChange('brand', event.target.value)
                                                    }
                                                >
                                                    <option value="">Selecione um equipamento</option>
                                                    <option value="SEM MARCA">EPSON</option>
                                                    <option value="ASSEMBLE">ASSEMBLE</option>
                                                </Form.Select>
                                               
                                            </div>

                                            <div className="col-4">
                                                <Form.Label htmlFor="model">Modelo</Form.Label>
                                                <Form.Select
                                                    size="sm"
                                                    className="searchCustomer"
                                                    id="model"
                                                    name="model"
                                                    value={formData.model}
                                                    onChange={(event) =>
                                                        handleFormChange('model', event.target.value)
                                                    }
                                                >
                                                    <option value="">Selecione um modelo</option>
                                                    <option value="i5">Intel I5 / SSD</option>
                                                    <option value="L395">L395</option>
                                                    <option value="ASPIRE">ASPIRE</option>
                                                </Form.Select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="returnContent">
                                        <div className="row">
                                            <div className="col-4">
                                                <Form.Label htmlFor="startCounter">Contador Inicial</Form.Label>
                                                <Form.Control
                                                    size="sm"
                                                    type="text"
                                                    className="searchCustomer"
                                                    id="startCounter"
                                                    name="startCounter"
                                                    placeholder="000.000"
                                                    value={formData.startCounter}
                                                    onChange={(event) =>{
                                                        handleFormChange('startCounter', event.target.value, formData.occurrence, 'startCounter')
                                                    }
                                                    }
                                                />
                                            </div>

                                            <div className="col-4">
                                            <Form.Label htmlFor="finalCounter">Contador Final</Form.Label>
                                                <Form.Control
                                                    size="sm"
                                                    type="text"
                                                    className="searchCustomer"
                                                    id="finalCounter"
                                                    name="finalCounter"
                                                    placeholder="000.000"
                                                    value={formData.finalCounter}
                                                    onChange={(event) =>{
                                                        handleFormChange('finalCounter', event.target.value, formData.occurrence, 'finalCounter')
                                                    }
                                                    }
                                                />
                                            </div>
                                            <div className="col-4">
                                            <Form.Label htmlFor="serviceCounter">Contador Total</Form.Label>
                                                <Form.Control
                                                    size="sm"
                                                    type="text"
                                                    className="searchCustomer"
                                                    id="serviceCounter"
                                                    name="serviceCounter"
                                                    placeholder="000.000"
                                                    value={formData.serviceCounter}
                                                    onChange={(event) =>
                                                        handleFormChange('serviceCounter', event.target.value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>


                                    <div className="returnContent">
                                        <div className="row">
                                            <div className="col-4">
                                                <Form.Label htmlFor="startDate">Data do Atendimento</Form.Label>
                                                <Calendar 
                                                className="searchCustomer" 
                                                name="startDate" 
                                                onDateChange={handleStartDateChange} 
                                                value={selectedStartDate}  
                                                />
                                            </div>

                                            <div className="col-4">
                                                <div>
                                                <Form.Label htmlFor="finishDate">Data de Finalização</Form.Label>
                                                    </div>
                                                <Calendar 
                                                className="searchCustomer" 
                                                name="finishDate" 
                                                onDateChange={handleFinishDateChange} 
                                                 value={selectedFinishDate} 
                                                 />
                                            </div>

                                            <div className="col-4">
                                                <Form.Label htmlFor="technician">Status</Form.Label>
                                                <Form.Select
                                                    size="sm"
                                                    className="searchCustomer"
                                                    id="status"
                                                    name="status"
                                                    value={formData.status}
                                                    onChange={(event) =>
                                                        handleFormChange('status', event.target.value)
                                                    }
                                                >
                                                    <option value="">Selecione um status</option>
                                                    <option value="EM ABERTO">Em Aberto</option>
                                                    <option value="PENDENTE">Pendente</option>
                                                    <option value="FECHADO">Fechado</option>
                                                </Form.Select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="returnContent">
                                        <div className="row">
                                            <div className="col-4">
                                                <Form.Label htmlFor="kmStart">Kilomentragem Inicial</Form.Label>
                                                <Form.Control
                                                    size="sm"
                                                    type="text"
                                                    className="searchCustomer"
                                                    id="kmStart"
                                                    name="kmStart"
                                                    placeholder="KM"
                                                    value={formData.kmStart}
                                                    onChange={(event) =>
                                                        handleFormChange('kmStart', event.target.value)
                                                    }
                                                />
                                            </div>

                                            <div className="col-4">
                                                <Form.Label htmlFor="kmFinish">Kilometragem Final</Form.Label>
                                                <Form.Control
                                                    size="sm"
                                                    className="searchCustomer"
                                                    id="kmFinish"
                                                    name="kmFinish"
                                                    placeholder='KM'
                                                    value={formData.kmFinish}
                                                    onChange={(event) =>
                                                        handleFormChange('kmFinish', event.target.value)
                                                    }
                                                />
                                                   
                                            </div>
                                            <div className="col-4">
                                            <Form.Label htmlFor="hospedage">Pernoite / Hospedagem</Form.Label>
                                                <Form.Control
                                                    size="sm"
                                                    className="searchCustomer"
                                                    id="hospedage"
                                                    name="hospedage"
                                                    placeholder='Hospedagem'
                                                    value={formData.hospedage}
                                                    onChange={(event) =>
                                                        handleFormChange('hospedage', event.target.value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="returnContent">
                                        <div className="row">
                                            <div className="col-4">
                                                <Form.Label htmlFor="budget">Valor do Orçamento</Form.Label>
                                                <Form.Control
                                                    size="sm"
                                                    type="text"
                                                    className="searchCustomer"
                                                    id="budget"
                                                    name="budget"
                                                    placeholder="R$"
                                                    value={formData.budget}
                                                    onChange={(event) =>
                                                        handleFormChange('budget', event.target.value)
                                                    }
                                                    required
                                                />
                                            </div>

                                            <div className="col-4">
                                                <Form.Label htmlFor="attendant">Valor Aprovado Do Serviço</Form.Label>
                                                <Form.Control
                                                    size="sm"
                                                    className="searchCustomer"
                                                    id="sale"
                                                    name="sale"
                                                    placeholder='R$'
                                                    value={formData.sale}
                                                    onChange={(event) =>
                                                        handleFormChange('sale', event.target.value)
                                                    }
                                                />
                                                   
                                            </div>
                                            <div className="col-4">
                                                <Form.Label htmlFor="guarantee">Tipo / Período de garantia</Form.Label>
                                                <Form.Select
                                                    size="sm"
                                                    className="searchCustomer"
                                                    id="guarantee"
                                                    name="guarantee"
                                                    value={formData.guarantee}
                                                    onChange={(event) =>
                                                        handleFormChange('guarantee', event.target.value)
                                                    }
                                                >
                                                    <option value="">Período de Garantia</option>
                                                    <option value="0">Sem Garantia</option>
                                                    <option value="1">Garantia Padrão Balcão</option>
                                                    <option value="2">15 dias</option>
                                                    <option value="3">30 dias</option>
                                                    <option value="4">45 dias</option>
                                                    <option value="5">60 dias</option>
                                                    <option value="6">90 dias</option>
                                                    <option value="7">1 mês</option>
                                                    <option value="8">2 meses</option>
                                                    <option value="9">3 meses</option>
                                                    <option value="10">6 meses</option>
                                                    <option value="11">1 ano</option>
                                                    <option value="12">18 meses</option>
                                                    <option value="13">24 mês</option>
                                                    <option value="14">Contrato de Outsourcing</option>
                                                    <option value="15">Contrato de Manutenção</option>
                                                </Form.Select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="returnContent">
                                        <div className="row rowLabel">
                                            <Form.Label htmlFor="others" className="">
                                                Outros
                                            </Form.Label>
                                            <Form.Control
                                                size="lg"
                                                type="text"
                                                className="searchCustomer"
                                                id="others"
                                                name="others"
                                                placeholder=""
                                                value={formData.others}
                                                onChange={(event) =>
                                                    handleFormChange('others', event.target.value)
                                                }
                                                required
                                            />

                                        </div>
                                    </div>
                                    <div className="returnContent">
                                        <div className="row rowLabel">
                                            <Form.Label htmlFor="observations" className="">
                                                Observações
                                            </Form.Label>
                                            <Form.Control
                                                size="lg"
                                                type="text"
                                                className="searchCustomer"
                                                id="observations"
                                                name="observations"
                                                placeholder=""
                                                value={formData.observations}
                                                onChange={(event) =>
                                                    handleFormChange('observations', event.target.value)
                                                }
                                                required
                                            />

                                        </div>
                                    </div>


                                </div>
                                <button className="form-btn" type="submit">
                                    Enviar
                                </button>
                            </Form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default UpdateServiceOrder;
