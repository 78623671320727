import React , { useState, useEffect } from 'react';

import { getUser } from '../../../services/api';

import '../../HomePage/styles/Index.css';


export default function TagUser() {
    
    const [user, setUser] = useState([]);
   
    const [loading, setLoading] = useState(true);

    useEffect(() =>{
       (async () =>{
        const response = await getUser();
        const users = response.data;
        setUser(users);
        console.log(users);
        setLoading(false);
       })();       
    }, []);


    if(loading){
        return<div>Carregando.....  ....  ...  ..  .</div>
      }
      

    return(
        <>      
            <div className="homeBox">
            <div className="boxText">
            <span>USUÁRIOS</span>
            {user.map((users) =>(<div key={users.id}>
                 <span>USUÁRIO: <a href={`mailto:${users.email}`}>{users.email}</a></span>
           </div>          
            ))}
            </div>
          </div>      
        </>
    )
}