import React, { useState, useEffect } from 'react'

import moment from 'moment';
import axios from 'axios';

import { getServiceOrder } from '../../../services/api';


import logo from '../../NavBar/img/apitLogo.jpg';

import '../styles/GetServiceOrders.css';

import Logout from '../../NavBar/components/Logout';
import Nav from '../../NavBar/components/Nav';
import Topo from '../../NavBar/components/Topo';

function GetServiceOrders() {

    const [orders, setOrders] = useState([]);
    const [token, setToken] = useState('');
    const [loading, setLoading] = useState(true);
    const [os, setOs] = useState([]);


    useEffect(() => {
        (async () => {
            const response = await getServiceOrder();
            setOrders(response.data);
            const token = response.data.token;
            setToken(token);
            setOrders(response.data, token);
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
            setLoading(false);
            console.log(response.data)
        })()
    }, [token]);

    const path = '/serviceOrders/';


    if (loading) {
        return <div>Carregando.....  ....  ...  ..  .</div>
    } else {
        const contadorStatus = {
            "EM ABERTO": 0,
            "PENDENTE": 0,
            "FECHADO": 0,
        };

        orders.forEach((order) => {
            contadorStatus[order.status]++;
        });

        return (
            <>
                <Topo logo={logo} companyName={"AP. IT Solutions"} logout={<Logout />} />
                <Nav />
                <section className="center">
                    <div className="container">
                        <div className="row">
                            <div className="mainTitle">
                                Ordens de Serviço
                            </div>

                            <div className="statusHeader">
                                <div className="">
                                    <span className="statusBox" >
                                        <div className={'statusOpen'}></div>
                                        <div className="statusText">ABERTAS:</div>
                                        <span> {contadorStatus['EM ABERTO']}</span>
                                    </span>
                                </div>
                                <div className="">
                                    <span className="statusBox" >
                                        <div className={'statusPending'}></div>
                                        <div className="statusText">PENDENTES:</div>
                                        <span> {contadorStatus.PENDENTE}</span>
                                    </span>
                                </div>
                                <div className="">
                                    <span className="statusBox">
                                        <div className={'statusClosed'}></div>
                                        <div className="statusText">FECHADAS:</div>
                                        <span>{contadorStatus.FECHADO}</span>
                                    </span>
                                </div>

                            </div>

                            <table className="">
                                <thead className="thead">
                                    <tr>
                                        <th>Cliente</th>
                                        <th>O.S nº</th>
                                        <th>Data da Abertura</th>
                                        <th>Defeito</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody className="orders">
                                    {orders.map((order) => (
                                        <tr key={order.service_order_id}>
                                            <td><a href={path + order.service_order_id}>{order.name}</a></td>
                                            <td>{order.number}</td>
                                            <td>{moment(order.date).format('DD/MM/YYYY')}</td> {/* Utilize o moment para formatar a data */}
                                            <td>{order.occurrence}</td>
                                            <td>
                                                <div className="statusBoxList">
                                                    <div>{order.status}</div>
                                                    <div className={`statusOS ${order.status === 'EM ABERTO' ? 'statusOpen' : order.status === 'PENDENTE' ? 'statusPending' : 'statusClosed'}`}></div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                        </div>
                    </div>
                </section>
            </>

        )
    }
}

export default GetServiceOrders