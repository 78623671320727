import React from 'react';
import { NavLink } from 'react-router-dom';
import { NavDropdown } from 'react-bootstrap';
import { MdFace, MdViewList, MdHome, MdAddBox, MdNote, MdBuild, MdFactory, MdChangeCircle, MdMonetizationOn } from 'react-icons/md';
import '../styles/SideNav.css';

const Nav = () => {
  const icoClient = <MdFace className='icon' key='icoClient' />;
  const icoClient2 = <MdBuild className='icon' key='icoClient2' />;
  const icoClient3 = <MdChangeCircle className='icon' key='icoClient3' />;
  const icoClient4 = <MdMonetizationOn className='icon' key='icoClient4' />;

  return (
    <nav>
      <NavLink className={'alonelink'} to="/welcome" key="home">
        <MdHome className='icon' /> Home
      </NavLink>
      <NavDropdown title={[icoClient, "    Clientes"]} className="dropdown-title" id="nav-dropdown" key='1'>
        <NavLink className="dropdown-item" to="/customers" key="1">
          <span> <MdViewList className='icon' /> Clientes </span>
        </NavLink>
        <NavLink className="dropdown-item" to="/newCustomer" key="2">
          <span> <MdAddBox className='icon' /> Novo Cliente </span>
        </NavLink>
      </NavDropdown>
      <NavDropdown title={[icoClient2, "    Ordens de Serviço"]} className="dropdown-title" id="nav-dropdown" key='2'>
        <NavLink className="dropdown-item" to="/serviceOrders" key="3">
          <span> <MdViewList className='icon' /> Todas as O.S </span>
        </NavLink>
        <NavLink className="dropdown-item" to="/newServiceOrder" key="4">
          <span> <MdAddBox className='icon' /> Gerar nova O.S </span>
        </NavLink>
      </NavDropdown>
      <NavDropdown title={[icoClient3, "   Produtos"]} className="dropdown-title" id="nav-dropdown" key='3'>
        <NavLink className="dropdown-item" to="/products" key="5">
          <span> <MdFactory className='icon' /> Todos os Produtos </span>
        </NavLink>
        <NavLink className="dropdown-item" to="/newProduct" key="6">
          <span> <MdAddBox className='icon' /> Novo Produto </span>
        </NavLink>
      </NavDropdown>
      <NavDropdown title={[icoClient4, "   Vendas"]} className="dropdown-title" id="nav-dropdown" key='4'>
        <NavLink className="dropdown-item" to="/sales" key="7">
          <span> <MdMonetizationOn className='icon' /> Vendas </span>
        </NavLink>
        <NavLink className="dropdown-item" to="/newSale" key="8">
          <span> <MdAddBox className='icon' /> Nova Venda </span>
        </NavLink>
      </NavDropdown>
      <NavLink className={'alonelink'} to="/" key="aloneLink">
        <MdNote className='icon' /> Relatórios
      </NavLink>
    </nav>
  )
}

export default Nav;
