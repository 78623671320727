import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import axios from 'axios';

import { getCustomer } from '../../../services/api';
import '../styles/NewServiceOrder.css';

function SearchCustomer({ name: inputName, id, value: inputValue, onChange: handleChange }) {
  const [selectedCustomer, setSelectedCustomer] = useState({ name: '', id: '' });
  const [selectedCustomerId, setSelectedCustomerId] = useState('');
  const [customers, setCustomers] = useState([]);
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [showResults, setShowResults] = useState(false); // Altere o valor inicial de showResults para false

  const onSearch = (searchValue) => {
    setSearchTerm(searchValue.toLowerCase());
    handleChange(inputName, searchValue); // Atualize o valor do input diretamente no handleChange
    setShowResults(true); // Defina showResults como true ao fazer uma pesquisa
  };

  useEffect(() => {
    (async () => {
      const response = await getCustomer();
      setCustomers(response.data);
      const token = response.data.token;
      setToken(token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      setLoading(false);
    })();
  }, [token]);

  const handleCustomerSelect = (name, id) => {
    setSelectedCustomer({ name, id });
    setSelectedCustomerId(id); // Corrija a atribuição para setSelectedCustomerId(id)
    handleChange(inputName, name, id); // Atualize o valor do input ID
    setShowResults(false); // Oculte a lista de resultados ao selecionar um elemento
    console.log(name, id, selectedCustomer);
  };

  if (loading) {
    return <div className="loading">Carregando..... .... ... .. .</div>;
  }

  const filteredCustomers = customers.filter((customer) => {
    const customerName = customer.name.toLowerCase();
    return customerName.startsWith(searchTerm);
  });

  return (
    <> 
   
      <Form.Label  htmlFor="{inputName}" className='' >Solicitante</Form.Label>
        <input
          type="text"
          className="searchCustomer"
          name={inputName}
          value={inputValue}
          onChange={(e) => onSearch(e.target.value)}
          onClick={() => setShowResults(true)} // Adicione esse onClick para exibir a lista de resultados ao clicar no input
        />
         <input
          type="text"
          className="searchCustomer"
          name={inputName + "_id"}
          value={selectedCustomerId}
          onChange={handleChange}
          hidden
        />   


      {showResults && filteredCustomers.length > 0 && (
        <div className="">
          {filteredCustomers.slice(0, 20).map((customer, index) => (
            <div
              key={index}
              className="dropdown-row"
              name="dropdown-row"
              onClick={() => handleCustomerSelect(customer.name, customer.id)}
            >
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a href="#" onClick={(e) => e.preventDefault()}>
                {customer.name}
              </a>
            </div>
          ))}
        </div>
      )}
    </>
  );
}

export default SearchCustomer;
