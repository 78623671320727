import React, {
  useState,
  useEffect,
  useContext
} from 'react';
import {
  AuthContext
} from '../../../context/auth';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import '../Login.css';

const Login = () => {
  const {
    authenticated,
    login
  } = useContext(AuthContext);
  const [loginUser, setLoginUser] = useState({
    email: '',
    password: ''
  });
  const [message, setMessage] = useState('');



  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoginUser({
      ...loginUser,
      [e.target.name]: e.target.value
    });
    login(loginUser.email, loginUser.password);
  };

  useEffect(() => {
    if (message) {
      ressetMessage();
    }
  }, [message]);

  const handleLogin = async () => {
    if (authenticated) {
      await setMessage('');
      ressetMessage();
    } else {
      setMessage('Usuário ou Senha incorretos')
    }
  };

  const ressetMessage = () => {
    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  const handleChange = (e) => {
    setLoginUser({
      ...loginUser,
      [e.target.name]: e.target.value
    });
  };

  return (
    <>
      <div className="loginBox" >
        <div>  <h5> Faça seu Login </h5></div> {
          message && <div className="message"> {message}</div>}
        <Form onSubmit={handleSubmit} className="" >
          <Form.Group>
            <Form.Label className="form-label"
              htmlFor="email" > Usuário </Form.Label>
            <Form.Control
              size="sm"
              type="text"
              className="form-control"
              id="email"
              name="email"
              autoComplete="email"
              placeholder="Nome"
              value={
                loginUser.email
              }
              onChange={
                handleChange
              }
            />

            <Form.Label className="form-label"
              htmlFor="password"> Senha </Form.Label>
            <Form.Control
              size="sm"
              type="password"
              className="form-control"
              id="password"
              name="password"
              placeholder="Password"
              autoComplete="current-password"
              value={
                loginUser.password
              }
              onChange={
                handleChange
              }
            />
          </Form.Group>
          <Form.Group className="loginBtn">
            <Button className="form-btn btn"
              variant="primary"
              type="submit"
              onClick={
                handleLogin
              } > Enviar </Button>
          </Form.Group>
        </Form>
      </div>
    </>
  )
}

export default Login;