import React from 'react';
import '../styles/NewServiceOrder.css';

function Calendar({ onDateChange }) {

    const onChange = (event) => {
        const selectedDate = event.target.value;
        onDateChange(selectedDate);
    }

    return (
    <>     
       <input type="date" className="osDate searchCustomer" name="date" onChange={onChange} />     
   </>
  )
}

export default Calendar