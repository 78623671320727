import React, { useState, useEffect } from 'react';

import { getCompany } from '../../../services/api';

import '../../HomePage/styles/Index.css';


export default function TagCompany() {

  const [company, setCompany] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const response = await getCompany();
      const companies = response.data;
      setCompany(companies);
      console.log(companies);
      setLoading(false);
    })();
  }, []);


  if (loading) {
    return <div>Carregando.....  ....  ...  ..  .</div>
  }


  return (
    <>
      {company.map((companies, id) => (
        <div key={companies.id} className="homeBox">
          <div className="boxText">
            <span>EMPRESA</span>
            <div><span>RAZÃO SOCIAL: </span>{companies.companyName}</div>
            <div><span>TELEFONE: </span>{companies.phone}</div>
            <div><span>EMAIL: <a href={`mailto:${companies.email}`}>{companies.email}</a></span></div>
            <div><span>CNPJ: </span>{companies.cnpj}</div>
          </div>
        </div>
      ))}
    </>
  )
}