import React from 'react';
import Login from './components/Login';
import './Login.css'

const LoginPage = () => {
  return (
    <>
  <div className="mainBkg">
    <div className="main">
    <div className="container centerLogin">
      <div>
        <h4>bem-vindo</h4>
      </div>
      <div>
       <Login />
      </div>
    </div> 
    </div>
    </div>
    </>
  )
}

export default LoginPage