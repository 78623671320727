import React from 'react';
import './App.css';
import AppRoutes from './AppRoutes';
import dotenv from 'dotenv';

dotenv.config(); // Carrega as variáveis de ambiente do arquivo .env


function App() {
  return (
    <div className="App">
     <AppRoutes />
    </div>
  );
}

export default App;
