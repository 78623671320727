import React, { useState, useEffect } from 'react';
import { MdEdit, MdDelete} from 'react-icons/md';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { getCustomer } from '../../../services/api';

import '../styles/CustomerList.css';

export default function CustomerList() {

  const [customers, setCustomer] = useState([]);

  const [loading, setLoading] = useState(true);

  const path = '/selectedCustomer/'

  useEffect(() => {
    (async () => {
      const response = await getCustomer();
      const customers = response.data;
      setCustomer(customers);
      console.log(customers);
      setLoading(false);
    })();
  }, [])

  if (loading) {
    return <div className="loading">Carregando.....  ....  ...  ..  .</div>
  }

  async function handleDelete(customerId) {
    const response = await getCustomer();
    console.log(response);

    const confirmDelete = window.confirm("Are you sure you want to delete this customer?");

    if (confirmDelete) {
      axios.delete(`/customers/${customerId}`)
        .then(response => {
          // remove the deleted customer from the state
          setCustomer(customers.filter(customer => customer.id !== customerId))
        })
        .catch(error => {
          console.log(error);
        })
    }
  }

  return (
    <>
      <section className="center">
        <div className="container">
          <div className="row"></div>
          <div className="table-container">
            <table className='table'>
              <thead>
                <tr>
                  <th>Name</th><th colSpan={2}>Telefone</th><th colspan={2}>Celular</th><th>Email</th><th></th><th></th>
                </tr>
              </thead>
              <tbody>
                {customers.map((customer) => (
                  <tr id={customers.id}>
                    <td>
                      <a href={path + customer.id}>{customer.name}</a></td>
                    <td colSpan={2}>{customer.phone}</td>
                    <td colSpan={2}>{customer.mobile}</td>
                    <td>{customer.email}</td>
                    <td>
                      <Link to={"/updateCustomer/" + customer.id}>
                        <MdEdit className='icon' />
                        {/* <Button className='btn  btn-warning' >Edit</Button> */}
                      </Link>
                    </td>
                    <td>
                      {/* <Button className='btn  btn-danger' onClick={() => handleDelete(customer.id)}>Delete</Button> */}
                      <Link to={"#"}>
                        <MdDelete className='icon' onClick={() => handleDelete(customer.id)} />
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  );
};
