import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import InputMask from 'react-input-mask';

import '../styles/NewCustomer.css';

import Topo from '../../NavBar/components/Topo';
import Nav from '../../NavBar/components/Nav';

import logo from '../../NavBar/img/apitLogo.jpg';
import Logout from '../../NavBar/components/Logout';

import { api } from '../../../services/api';

function UpdateCustomerForm() {
  const { id } = useParams();
  const [customerId, setCustomerId] = useState(id);
  const [customerData, setCustomerData] = useState({});
  const navigate = useNavigate();

  const [customer, setCustomer] = useState({
    customer_code: '',
    name: '',
    cnpj: '',
    inscEstadual: '',
    email: '',
    website: '',
    contact: '',
    contact2: '',
    phone: '',
    phone2: '',
    mobile: '',
    mobile2: '',
    address: {
      zipcode: '',
      street: '',
      number: '',
      complement: '',
      neighborhood: '',
      city: '',
      state: '',
      uf: ''
    }
  });
  const [message, setMessage] = useState('');
  const [token, setToken] = useState('');

  useEffect(() => {
    const getCustomer = async () => {
      try {
        // const response = await api.get(`http://localhost:3001/customers/${customerId}`);
        const response = await api.get(`/customers/${customerId}`);
        const token = response.data.token;
        setToken(token);
        setCustomerData(response.data, token);
        console.log(response.data);
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
      } catch (error) {
        console.log(error);
      }
    };
    if (customerId) {
      getCustomer();
    }
  }, [customerId, token]);

  useEffect(() => {
    if (customerData && Object.keys(customerData).length) { // Verificar se os dados do cliente foram carregados
      setCustomer({...customerData, id: customerId});
    }
  }, [customerData, customerId]);

  
  const handleChange = (e) => {
    if (e.target.id === 'customer_id') {
      setCustomerId(e.target.value || '');
    } else if (e.target.name !== 'address') {
      setCustomer({
        ...customer,
        [e.target.name]: e.target.value || ''
      });
    } else {
      setCustomer({
        ...customer,
        address: {
          ...customer.address,
          [e.target.customer_id]: e.target.value || ''
        }
      });
    }

  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
       const response = await api.put(`/customers/${customerId}`, customer);
      setMessage(alert('Cliente atualizado com sucesso') && 'Cliente atualizado com sucesso');
      setCustomer({
        customer_code: '',
        name: '',
        cnpj: '',
        inscEstadual: '',
        email: '',
        website: '',
        contact: '',
        contact2: '',
        phone: '',
        phone2: '',
        mobile: '',
        mobile2: '',
        address: {
          zipcode: '',
          street: '',
          number: '',
          complement: '',
          neighborhood: '',
          city: '',
          state: '',
          uf: ''
        }
      });
      console.log(response);
   } catch (error) {
      console.log(error);
    }
    navigate('/customers');
  };

  return (
    <>
     <Topo
     logo={logo}
     companyName={"AP. IT Solutions"}
     logout={<Logout />}
     />  
      <Nav />

      <section className="center">
              <div className="container">
                  <div className="row">
                  <div className="mainTitle">
                      Atualizar dados do cliente
                      {message && <div className="message">{message}</div>}
                 </div>
                    
        <Form onSubmit={handleSubmit} className="">     
          <div className="row">
            <div className="col-4 col-A">
              <Form.Group>
                <Form.Label className="form-label" htmlFor="customer_id" hidden >ID</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  className="form-control"
                  id="customer_id"
                  name="customer_id"
                  placeholder="ID do cliente"
                  value= {customerId}
                  onChange={handleChange}
                  hidden
                />
                <Form.Label className="form-label" htmlFor="customer_code" hidden>Código</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  className="form-control"
                  id="customer_code"
                  name="customer_code"
                  placeholder="Código"
                  value={customer.customer_code}
                  onChange={handleChange}
                  hidden
                />

                <Form.Label className="form-label" htmlFor="name" >Nome</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  placeholder="Nome"
                  value={customer.name}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="form-label" htmlFor="cnpj" >CNPJ</Form.Label>
                <InputMask
                  size="sm"
                  mask="99.999.999/9999-99"
                  type="text"
                  className="form-control form-control-sm readOnly"
                  id="cnpj"
                  name="cnpj"
                  placeholder="00.000.000/0000-00"
                  value={customer.cnpj}
                  onChange={handleChange}
                  readOnly
                  />        

                <Form.Label className="form-label" htmlFor="inscEstadual" >Inscrição Estadual</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  className="form-control"
                  id="inscEstadual"
                  name="inscEstadual"
                  placeholder="000.000.000"
                  value={customer.inscEstadual}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label className="form-label" htmlFor="contact" >Contato</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  className="form-control"
                  id="contact"
                  name="contact"
                  placeholder="Contato"
                  value={customer.contact}
                  onChange={handleChange}
                />

                <Form.Label className="form-label" htmlFor="contact2" >Contato</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  className="form-control"
                  id="contact2"
                  name="contact2"
                  placeholder="Contato 2"
                  value={customer.contact2}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="form-label" htmlFor="email" >Email</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  className="form-control"
                  id="email"
                  name="email"
                  placeholder="email@email.com"
                  value={customer.email}
                  onChange={handleChange}
                />

                <Form.Label className="form-label" htmlFor="website" >Web Site</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  className="form-control"
                  id="website"
                  name="website"
                  placeholder="wwww..."
                  value={customer.website}
                  onChange={handleChange}
                />
              </Form.Group>
            </div>
            <div className="col-4 col-A">
               <Form.Group>
                <Form.Label className="form-label" htmlFor="phone" >Telefone</Form.Label>
                <InputMask
                  size="sm"
                  mask="(99) 99999-9999"
                  type="text"
                  className="form-control form-control-sm"
                  id="phone"
                  name="phone"
                  placeholder="(XX) XXXXX-XXXX"
                  value={customer.phone}
                  onChange={handleChange}
                />

                <Form.Label className="form-label" htmlFor="phone2" >Telefone</Form.Label>
                <InputMask
                  size="sm"
                  mask="(99) 99999-9999"
                  type="text"
                  className="form-control form-control-sm"
                  id="phone2"
                  name="phone2"
                  placeholder="(XX) XXXXX-XXXX"
                  value={customer.phone2}
                  onChange={handleChange}
                />

                <Form.Label className="form-label" htmlFor="mobile" >Celular</Form.Label>
                <InputMask
                  size="sm"
                  mask="(99) 99999-9999"
                  type="text"
                  className="form-control form-control-sm"
                  id="mobile"
                  name="mobile"
                  placeholder="(XX) XXXXX-XXXX"
                  value={customer.mobile}
                  onChange={handleChange}
                />

                <Form.Label className="form-label" htmlFor="mobile2" >Celular</Form.Label>
                <InputMask
                  size="sm"
                  mask="(99) 99999-9999"
                  type="text"
                  className="form-control form-control-sm"
                  id="mobile2"
                  name="mobile2"
                  placeholder="(XX) XXXXX-XXXX"
                  value={customer.mobile2}
                  onChange={handleChange}
                />

              </Form.Group>
            </div>
            <div className="col-4 col-A">
              <Form.Group>
                <Form.Label className="form-label" htmlFor="zipcode" >CEP</Form.Label>
                <InputMask
                  size="sm"
                  mask="99999-999"
                  type="text"
                  className="form-control form-control-sm"
                  id="zipcode"
                  name="zipcode"
                  placeholder="00000-000"
                  value={customer.zipcode}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="form-label" htmlFor="street" >Endereço</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  className="form-control"
                  id="street"
                  name="street"
                  placeholder="Endereço"
                  value={customer.street}
                  onChange={handleChange}
                />
                <Form.Label className="form-label" htmlFor="number" >Número</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  className="form-control"
                  id="number"
                  name="number"
                  placeholder="00000"
                  value={customer.number}
                  onChange={handleChange}
                />
                <Form.Label className="form-label" htmlFor="complement" >Complemento</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  className="form-control"
                  id="complement"
                  name="complement"
                  placeholder="A"
                  value={customer.complement}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="form-label" htmlFor="neighborhood" >Bairro</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  className="form-control"
                  id="neighborhood"
                  name="neighborhood"
                  placeholder="Bairro"
                  value={customer.neighborhood}
                  onChange={handleChange}
                />
                <Form.Label className="form-label" htmlFor="city" >Cidade</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  className="form-control"
                  id="city"
                  name="city"
                  placeholder="Cidade"
                  value={customer.city}
                  onChange={handleChange}
                />
                <Form.Label className="form-label" htmlFor="state" hidden >Estado</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  className="form-control"
                  id="state"
                  name="state"
                  placeholder="Estado"
                  value={customer.state}
                  onChange={handleChange}
                  hidden
                />
                 <Form.Label className="form-label" htmlFor="uf" >UF</Form.Label>
                  <Form.Select
                    size="sm"
                    className="form-control"
                    id="uf"
                    name="uf"
                    value={customer.uf}
                    onChange={handleChange}
                  >
                   <option value="">Selecione um estado</option>
                    <option value="SP">SÃO PAULO</option>
                    <option value="AC">ACRE</option>
                    <option value="AL">ALAGOAS</option>
                    <option value="AP">AMAPÁ</option>
                    <option value="AM">AMAZONAS</option>
                    <option value="BA">BAHIA</option>
                    <option value="CE">CEARÁ</option>
                    <option value="DF">DISTRITO FEDERAL</option>
                    <option value="ES">ESPÍRITO SANTO</option>
                    <option value="GO">GOIÁS</option>
                    <option value="MA">MARANHÃO</option>
                    <option value="MT">MATO GROSSO</option>
                    <option value="MS">MATO GROSSO DO SUL</option>
                    <option value="MG">MINAS GERAIS</option>
                    <option value="PA">PARÁ</option>
                    <option value="PB">PARAÍBA</option>
                    <option value="PR">PARANÁ</option>
                    <option value="PE">PERNAMBUCO</option>
                    <option value="PI">PIAUÍ</option>
                    <option value="RJ">RIO DE JANEIRO</option>
                    <option value="RN">RIO GRANDE DO NORTE</option>
                    <option value="RS">RIO GRANDE DO SUL</option>
                    <option value="RO">RONDÔNIA</option>
                    <option value="RR">RORAIMA</option>
                    <option value="SC">SANTA CATARINA</option>
                    <option value="SP">SÃO PAULO</option>
                    <option value="SE">SERGIPE</option>
                    <option value="TO">TOCANTINS</option>
                  </Form.Select>
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center">
              <Form.Group>
                <Button className="form-btn" variant="primary" type="submit">Editar</Button>
              </Form.Group>
            </div>
          </div>
        </Form>
      </div>
      </div>
      </section>
    </>
  );
}

export default UpdateCustomerForm;