import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { MdAccountBox, MdMarkunread, MdContactPhone, MdContactMail, MdFace } from 'react-icons/md';


import { api } from '../../../services/api';
import '../styles/GetCustomerSelected.css';

import Nav from '../../NavBar/components/Nav';
import Topo from '../../NavBar/components/Topo';

import logo from '../../NavBar/img/apitLogo.jpg';
import Logout from '../../NavBar/components/Logout';

function GetCustomerSelected() {
  const { id } = useParams();
  const [customerData, setCustomerData] = useState({});
  const [token, setToken] = useState('');

  useEffect(() => {
    const fetchCustomerData = async () => {
      try {
        const response = await api.get(`/customers/${id}`);
        const { data } = response;
        const { token, ...customer } = data;
        setToken(token);
        setCustomerData(customer);
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      } catch (error) {
        console.log(error);
      }
    };

    fetchCustomerData();
  }, [id, token]);

  return (
    <>
      <Topo
        logo={logo}
        companyName={"AP. IT Solutions"}
        logout={<Logout />}
      />
      <Nav />
      <section className="center">
        <div className="container">
          <div className="row">
            <div className="mainTitle">
              Detalhamento do Cliente
            </div>
            <div className="section1">
              <div className="sectionCustomerView">
                <div className="customerView">
                  <label>Cliente</label>
                  <div><MdAccountBox className='icon' /> {customerData.name}</div>
                </div>
                <div className="customerView">
                  <label>
                    <div className="row-Title">
                      {customerData.cnpj && (
                        <div>CNPJ</div>
                      )}
                      {customerData.inscEstadual && (
                        <div>Inscrição Estadual</div>
                      )}
                      {customerData.inscMunicipal && (
                        <div>Inscrição Municipal</div>
                      )}

                    </div>
                  </label>

                  <div className="row-Data">
                    {customerData.cnpj && (
                      <div>
                        <MdAccountBox className="icon" /> {customerData.cnpj}
                      </div>
                    )}
                    {customerData.inscEstadual && (
                      <div>
                        <MdAccountBox className="icon" /> {customerData.inscEstadual}
                      </div>
                    )}
                    {customerData.inscMunicipal && (
                      <div>
                        <MdAccountBox className="icon" /> {customerData.inscMunicipal}
                      </div>
                    )}
                  </div>
                </div>

                <div className="customerView">
                  <label>Endereço</label>
                  <div><MdMarkunread className='icon' /> {customerData.street}, {customerData.number}, {customerData.city}, {customerData.uf}, {customerData.zipcode}</div>
                </div>

                <div className="customerView">
                  <label>Contatos</label>
                  <div className='row-Data'>
                    {customerData.contact && (
                      <div><MdFace className='icon' /> {customerData.contact}</div>
                    )}

                    {customerData.contact2 && (
                      <div><MdFace className='icon' /> {customerData.contact2}</div>
                    )}
                  </div>
                </div>

                <div className="customerView">
                  <label>Telefones</label>
                  <div className='row-Data'>
                    {customerData.phone && (
                      <div><MdContactPhone className='icon' /> {customerData.phone}</div>
                    )}

                    {customerData.phone2 && (
                      <div><MdContactPhone className='icon' /> {customerData.phone2}</div>
                    )}

                    {customerData.mobile && (
                      <div><MdContactPhone className='icon' /> {customerData.mobile}</div>
                    )}

                    {customerData.mobile2 && (
                      <div><MdContactPhone className='icon' /> {customerData.mobile2}</div>
                    )}

                  </div>
                </div>
                <div className="customerView">
                  <label>Email / Redes Sociais</label>
                  <div className='row-Data'>
                    {customerData.email && (
                      <div> <MdContactMail className='icon' /> {customerData.email} </div>
                    )}
                    {customerData.socialMedia && (
                      <div> <MdContactMail className='icon' /> {customerData.email} </div>
                    )}
                    {customerData.socialMedia2 && (
                      <div> <MdContactMail className='icon' /> {customerData.email} </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default GetCustomerSelected;
