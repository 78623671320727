import React from 'react'

import '../styles/Index.css';

import  CustomerList  from '../../CustomerPage/components/CustomerList';
import logo from '../../NavBar/img/apitLogo.jpg';

import Nav from '../../NavBar/components/Nav';
import Logout from '../../NavBar/components/Logout';
import Topo from '../../NavBar/components/Topo';

function GetPage() {
  return (
    <>
    <style>
    {`
      .topo {
        background-color: transparent;
        font-size: 1rem
      }
    `}
  </style>

     <html lang="en">
<head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
</head>
<body>
<header class="navbar-top">
<Topo  logo={logo} companyName={"CLIENTES"} logout={<Logout />} />
     
  </header>
  <nav class="navbar-side">
    <Nav />
  </nav>
  <div class="content">
    <CustomerList />
  </div>
  <footer class="footer">
  <div className="row">
    <div className="col-4">

    </div>
    <div className="col-4">
      A P I.T Solutions 
    </div>
    <div className="col-4">
      
      
    </div>
  </div>
  </footer>
</body>
</html>
</>
  )
}

export default GetPage