import React from 'react';

import '../styles/Index.css';

function Topo(props) {

  
  return (
    <>
    <div className="topo">
        <div><img className="logo" src={props.logo} alt="Logo" /></div>
           <div>{props.companyName}</div>
             <div>
              {props.logout}
            </div>
    </div>
    </>
  )
}

export default Topo