import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import React, { useContext } from 'react';

import './pages/LandingPage/styles/LandingPage.css';

import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import UserPage from './pages/UserPage';
import CustomerPage from './pages/CustomerPage';
import serviceOrders from './pages/ServiceOrdersPage';
import LandingPage from './pages/LandingPage/Index';

import CreateCustomerForm from './pages/CustomerPage/components/NewCustomer';
import UpdateCustomerForm from './pages/CustomerPage/components/UpdateCustomer';
import GetCustomerComponent from './pages/CustomerPage/components/GetCustomer';
import GetServiceOrders from './pages/ServiceOrdersPage/components/GetServiceOrders';
import NewServiceOrder from './pages/ServiceOrdersPage/components/NewServiceOrder';
import UpdateServiceOrder from './pages/ServiceOrdersPage/components/UpdateServiceOrder';
import GetCustomerSelected from './pages/CustomerPage/components/GetCustomerSelected';
import GetPage from './pages/SPAPage/components/GetPage';

import { AuthProvider, AuthContext } from './context/auth';
import ForgetPassword from './pages/UserPage/components/ForgetPassword';

const AppRoutes = () => {

  const Private = ({ children }) => {
    const { authenticated, loading } = useContext(AuthContext);

    if (loading) {
      return <div className="loading">Carregando...</div>
    }

    if (!authenticated) {
      return <Navigate to='/login' />;
    }

    return children;
  }
  return (
    <Router>
      <AuthProvider>
        <Routes>

          {/* <Route exact path="/" element={<LandingPage />} /> */}
          <Route exact path="/" element={<LoginPage />} />

          {/* <Route exact path="/login" element={<LoginPage />} /> */}

          <Route exact path="/forgetPassword" element={<ForgetPassword />} />

          <Route exact path="/welcome"
            element={<Private><HomePage /></Private>}
          />

          <Route exact path="/customers"
            element={<Private><GetCustomerComponent /></Private>}
          />

          <Route exact path="/newCustomer"
            element={<Private><CreateCustomerForm /></Private>}
          />

          <Route exact path="/updateCustomer/:id"
            element={<Private><UpdateCustomerForm /></Private>}
          />

          <Route exact path="/customers/:id"
            element={<Private><GetCustomerComponent /></Private>}
          />

          <Route exact path="/selectedCustomer/:id"
            element={<Private><GetCustomerSelected /></Private>}
          />

          <Route exact path="/"
            element={<Private><UserPage /></Private>}
          />

          <Route exact path="/serviceOrders"
            element={<Private><GetServiceOrders /></Private>}
          />

          <Route exact path="/newServiceOrder"
            element={<Private><NewServiceOrder /></Private>}
          />

          <Route exact path="/serviceOrders/:id"
            element={<Private><UpdateServiceOrder /></Private>}
          />

          <Route exact path="/spaPage"
            element={<Private><GetPage /></Private>}
          />

        </Routes>
      </AuthProvider>
    </Router>
  )
}

export default AppRoutes